import React from 'react';
import moment from 'moment';
import is from 'is_js';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Art.module.scss';

const Art = ({ category, image }) => {
  const setImageClass = () => {
    if (image) {
      return styles[`art__image--${category}-${image}`];
    }
    return styles[
      `art__image--${category}-${is.even(moment().date()) ? 'even' : 'odd'}`
    ];
  };

  return (
    <div className={styles.art}>
      <LazyLoadComponent>
        <div className={classNames(styles.art__image, `${setImageClass()}`)} />
      </LazyLoadComponent>
    </div>
  );
};

Art.propTypes = {
  category: PropTypes.string.isRequired,
  image: PropTypes.oneOf(['even', 'odd']),
};

Art.defaultProps = {
  image: null,
};

export default Art;
